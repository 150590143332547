var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[_c('h2',{staticClass:"text-center mb-5",style:({
        styleTitle: _vm.styleTitle,
        fontFamily: 'Georama',
        fontSize: 24,
        fontWeight: 700,
        marginTop: '35px',
        lineheight: '20px',
      })},[_vm._v(" Bravo ")]),_c('v-row',{staticClass:"mt-13",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-xl pa-2 mx-10",staticStyle:{"margin-bottom":"30px"},attrs:{"width":"350","height":"204","color":_vm.color,"variant":_vm.variant}},[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_c('v-card-item',[_c('v-card-title',{style:({
                  fontWeight: 600,
                  fontSize: '16px',
                  fontFamily: _vm.Georama,
                  lineHeight: '20px',
                })},[_c('span',{staticClass:"pr-5",staticStyle:{"font-weight":"700","font-size":"34px","font-family":"Georama","line-height":"20px"}},[_vm._v("1")]),_vm._v("Traitement en cours ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"600","font-size":"14px","font-family":"Georama","line-height":"20px"}},[_vm._v(" La modification a été bien prise en compte. Votre commande est en cours d'analyse par nos services. ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"400","font-size":"14px","font-family":"Georama","line-height":"20px","padding-top":"20px"}},[_vm._v(" Votre commande sera analysée par nos équipes ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }