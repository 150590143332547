<template>
  <div align="center">
    <div><v-icon x-large color="green">mdi-check-circle</v-icon></div>
    <h1 :style="styleTitle" v-if="shortliveApiKey == undefined">Bravo</h1>
    <h1 :style="styleTitle" v-else>Commande en cours de traitement</h1>
    <br />
    <div>
      <v-row class="pt-1">
        <v-col>
          <div class="text" align="center" v-if="shortliveApiKey == undefined">
            Votre paiement a bien été enregistré. La commande est en traitement.
          </div>
          <div class="text" align="center" v-else>
            Votre commande est en traitement.
          </div>
        </v-col>
      </v-row>
    </div>
    <v-card-text class="py-0">
      <v-timeline align-top dense>
        <v-timeline-item
          :color="timelineColor"
          small
          v-if="shortliveApiKey == undefined"
        >
          <v-row class="pt-1">
            <v-col>
              <strong>Reçu de paiement</strong>
              <div><v-icon>mdi-ticket-percent</v-icon></div>
              <div class="text-caption" align="start">
                Vous allez recevoir votre reçu de paiement par email.
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item :color="timelineColor" small>
          <v-row class="pt-1">
            <v-col>
              <strong>Traitement</strong>
              <div><v-icon>mdi-image-search</v-icon></div>
              <div class="text-caption" align="start">
                Votre commande sera analysée par nos équipes. Si votre photo ou
                signature risque d'être refusée par l'administration, vous serez
                invité par email à refaire votre photo ou signature
                gratuitement.
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>

        <v-timeline-item color="grey" icon-color="grey lighten-2" small>
          <v-row class="pt-1">
            <v-col>
              <strong>Validation</strong>
              <div><v-icon>mdi-check-circle</v-icon></div>
              <div class="text-caption" align="start">
                Une fois votre commande validée, un code ephoto sera envoyé à
                votre agence.
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
    <h1 :style="styleTitle">Merci pour votre confiance.</h1>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'PaymentSuccess',
  data() {
    return {
      shortliveApiKey: undefined,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      timelineColor: localStorageService.getStyle(
        location.hostname,
        'timelineColor',
      ),
    }
  },
  mounted() {
    this.shortliveApiKey = localStorageService.getValue('shortliveApiKey')
    localStorageService.clearAll()
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
