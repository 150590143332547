<template>
  <div>
    <!-- <div :rail="rail" permanent @click="rail = false" class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-main>-->
    <v-sheet>
      <!-- <h2
        class="text-center mb-5"
        :style="{
          styleTitle,
          fontFamily: 'Georama',
          fontSize: 24,
          fontWeight: 700,
          marginTop: '35px',
          lineheight: '20px',
        }"
      >
        Bravo
      </h2> -->
      <v-row align="center" justify="center" class="mt-13">
        <v-col cols="auto">
          <v-card
            width="350"
            height="204"
            style="margin-bottom: 30px"
            :color="color"
            :variant="variant"
            class="rounded-xl pa-2 mx-10"
          >
            <v-row align="center" class="pa-3">
              <v-card-item>
                <v-card-title
                  :style="{
                    fontWeight: 600,
                    fontSize: '16px',
                    fontFamily: Georama,
                    lineHeight: '20px',
                  }"
                  ><!-- <span
                    style="
                      font-weight: 700;
                      font-size: 34px;
                      font-family: Georama;
                      line-height: 20px;
                    "
                    class="pr-5"
                    >1</span
                  > -->
                  <v-icon>mdi-close-octagon</v-icon>Erreur
                </v-card-title>
                <div
                  class="pl-5"
                  style="
                    font-weight: 400;
                    font-size: 14px;
                    font-family: Georama;
                    line-height: 20px;
                    padding-top: 20px;
                  "
                >
                  Veuillez contacter votre auto-école pour pouvoir finaliser
                  votre commande.
                </div>
              </v-card-item>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <!--</v-main>-->
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
//import Accueil from './Accueil.vue'

export default {
  name: 'AlertAgency',
  /*components: {
    Accueil,
  },*/
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      isClicked: false,
    }
  },
  mounted() {
    if (!localStorageService.getValue('shortliveApiKey')) {
      this.$router.push({ name: 'NotAuthorized' })
      return
    }
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
    localStorageService.clearAll()
  },
}
</script>
<style>
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}
</style>
